<template>
    <el-main>
        <div class="searchdiv">
            <div class="searchform">
                <el-input v-model="searchinfo.role_name" :placeholder="$t('user.role_name')" :label="$t('user.role_name')" clearable></el-input>
                <el-button type="primary" @click="dosearch()" icon="el-icon-search" :disabled="isbusy" :loading="isbusy">{{$t('global.search')}}</el-button>
            </div>
            <div>
                <el-button type="primary" @click="addrole('roleform')" icon="el-icon-plus">{{ $t('user.addrole') }}</el-button>
            </div>
        </div>
        <div class="pagecontent">
            <el-table
                    ref="multipleTable"
                    :data="rolelist"
                    tooltip-effect="dark"
                    style="width: 100%">
                <el-table-column
                        :label="$t('user.role_name')"
                        width="120" prop="role_name">
                </el-table-column>
                <el-table-column :label="$t('user.is_system')">
                    <template slot-scope="scope">{{ scope.row.is_sytem?$t('global.yes'):$t('global.no') }}</template>
                </el-table-column>
                <el-table-column
                        :label="$t('global.remarks')"
                        prop="remarks">
                </el-table-column>
                <el-table-column
                        :label="$t('global.state')"
                        width="120" prop="status">
                    <template slot-scope="scope">
                        <el-link :type="scope.row.status?'success':'danger'"
                                 @click="setenable(scope.row)"
                                 :disabled="scope.row.is_system===1">{{ scope.row.status?$t('global.enable'):$t('global.disable') }}</el-link>
                    </template>
                </el-table-column>
                <el-table-column :label="$t('global.action')" width="200" fixed="right">
                    <template slot-scope="scope">
                        <el-link class="optlink"
                                 @click="editrole(scope.row)"  :disabled="scope.row.is_system===1">{{$t('global.edit')}}</el-link>
                        <el-link class="optlink"
                                 @click="delrole(scope.row)"  :disabled="scope.row.is_system===1">{{$t('global.delete')}}</el-link>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    @size-change="SizeChange"
                    @current-change="getrolelist"
                    :current-page="searchinfo.page"
                    :page-sizes="PAGE.pageSizes"
                    :page-size="searchinfo.pagesize"
                    :layout="PAGE.layout"
                    :total="totalRows">
            </el-pagination>
        </div>
        <el-dialog :title="roleformdata.role_id?$t('user.editrole'):$t('user.addrole')" :visible.sync="isshowdialog" :close-on-click-modal="false">
            <el-form :model="roleformdata" :rules="rules" ref="roleform" class="roleForm" :show-message="false">
                <el-form-item :label="$t('user.role_name')" label-width="120px" prop="role_name" required>
                    <el-input v-model="roleformdata.role_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item :label="$t('global.state')" label-width="120px" required>
                    <el-switch v-model="roleformdata.status" :active-value="1" :inactive-value="0" :active-text="$t('global.yes')" :inactive-text="$t('global.no')"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('global.remarks')" label-width="120px" prop="remarks">
                    <el-input type="textarea" v-model="roleformdata.remarks"></el-input>
                </el-form-item>
                <el-form-item :label="$t('user.privileges')" label-width="120px">
                    <el-tree :data="treelist"
                            :props="defaultProps"
                            show-checkbox
                            node-key="id"
                            :default-expanded-keys="roleformdata.expandedkeys"
                            :default-checked-keys="roleformdata.checkedkeys"
                            @check="handleCheck">
                    </el-tree>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isshowdialog = false" :disabled="isbusy" :loading="isbusy">{{$t('global.cancel')}}</el-button>
                <el-button type="primary" @click="dosubmit('roleform')" :disabled="isbusy" :loading="isbusy">{{$t('global.submit')}}</el-button>
            </div>
        </el-dialog>
    </el-main>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'role',
        components: {},
        data() {
            return {
                searchinfo: {
                    page:1,
                    pagesize: this.PAGE.pageSize,
                    role_name: '',
                },
                rolelist: [],
                multipleSelection: [],
                totalRows: 0,
                defaultroleinfo: {
                    role_id: 0,
                    role_name: '',
                    status: 1,
                    authlist: '',
                    remarks: '',
                    expandedkeys: [],
                    checkedkeys: []
                },
                roleformdata: {},
                rules: {},
                isshowdialog: false,
                nodelist: [],
                treelist: [],
                defaultProps: {
                    children: 'children',
                    label: 'title'
                },
                isbusy: false,
            }
        },
        methods: {
            SizeChange(val){
                this.searchinfo.pagesize=val;
                this.getrolelist(1);
            },
            async dosearch(){
                this.getrolelist(1);
            },
            async getrolelist(page){
                this.searchinfo.page=page||this.searchinfo.page;
                this.isbusy = true;
                let res= await this.httpget('/system/role/index',this.searchinfo);
                if(res.code===0){
                    this.rolelist=res.data.data;
                    this.rolelist.forEach((role) => {
                        if(role.authlist == '' || role.authlist == null){
                            role.authlist = [];
                        }else{
                            role.authlist = role.authlist.split(',');
                        }
                    });
                    this.totalRows = res.data.total;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                }
            },
            in_array(search,array){
                for(var i in array){
                    if(array[i]==search){
                        return true;
                    }
                }
                return false;
            },
            setdefault(authlist){
                this.roleformdata.expandedkeys = [];
                this.roleformdata.checkedkeys = [];
                this.loopdefault(this.nodelist,authlist);
            },
            loopdefault(nodelist,authlist){
                nodelist.forEach((node)=>{
                    if(this.in_array(node.id,authlist)){
                        if(node.children){
                            this.roleformdata.expandedkeys.push(node.id);
                            this.loopdefault(node.children,authlist)
                        }else{
                            this.roleformdata.checkedkeys.push(node.id);
                        }
                    }
                })
            },
            editrole(item){
                this.getnodelist(item,'edit');
            },
            addrole(formName){
                this.getnodelist(formName,'add');

            },
            dosubmit(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let submitdata = this.cloneData(this.roleformdata);
                submitdata.expandedkeys = undefined;
                submitdata.checkedkeys  = undefined;
                let res= await this.httppost('/system/role/save', submitdata);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.getrolelist(1);
                    this.roleformdata={};
                    this.isshowdialog=false;
                    this.isbusy = false;
                } else {
                    this.isbusy = false;
                    this.$message({type: 'error', message: res.msg});
                }
            },
            async setenable(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/role/setenable',{role_id:item.role_id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        let idx=this.rolelist.findIndex((value) => {
                            return value.role_id === item.role_id
                        })
                        if(idx!==-1){
                            this.rolelist[idx].status = Math.abs(this.rolelist[idx].status-1);
                        }
                        this.isbusy = false;
                    } else {
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async delrole(item){
                this.$confirm(this.$t('global.areyousure')).then( async () => {
                    this.isbusy = true;
                    let res= await this.httpput('/system/role/delete',{role_id:item.role_id});
                    if(res.code===0){
                        this.$message({type: 'success', message: res.msg});
                        this.getrolelist();
                        this.isbusy = false;
                    } else {
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                }).catch(() => {
                    this.isbusy = false;
                    this.$message({type: 'info', message: 'Canceled'});
                });
            },
            async getnodelist(info='',type=''){
                // 编辑的地方get一次
                //if(this.nodelist.length<=0){
                    this.isbusy = true;
                    let res= await this.httpget('/system/role/save');
                    if(res.code===0){
                        this.nodelist=this.cloneData(res.data);
                        this.isbusy = false;
                        if( type == 'edit' ){
                          this.roleformdata=null;
                          this.roleformdata = this.cloneData(info);
                          this.setdefault(this.roleformdata.authlist);
                          this.isshowdialog=true;
                        }
                        if( type == 'add' ){
                          this.roleformdata = this.cloneData(this.defaultroleinfo);
                          this.roleformdata.authlist = [];
                          this.isshowdialog=true;
                          this.$nextTick(()=>{
                            this.$refs[info].resetFields();
                          });
                        }
                    } else {
                        this.isbusy = false;
                        this.$message({type: 'error', message: res.msg});
                    }
                //}
                this.treelist=this.cloneData(this.nodelist);
            },
            handleCheck(data, checknode){
                let newauthlist=[];
                this.roleformdata.authlist = newauthlist.concat(checknode.checkedKeys, checknode.halfCheckedKeys);
                this.setdefault(this.roleformdata.authlist);
            }
        },
        mounted() {
            this.getrolelist(1);
        }
    }
</script>
<style lang="scss">
    .roleForm{
        .el-checkbox{
            padding-right: 10px !important;
        }
    }
</style>
